import * as Yup from "yup";
import { formErrors } from "../../../helpers";

const initialValues: any = {
  pergunta1: "",
  pergunta2: "",
  pergunta3: "",
  pergunta4: "",
  pergunta5: "",
  pergunta6: "",
  pergunta7: "",
  pergunta8: "",
  pergunta9: "",
  pergunta10: "",
  pergunta11: "",
  pergunta12: "",
  pergunta13: "",
  pergunta14: "",
  pergunta15: "",
  pergunta16: "",
  pergunta17: "",
  pergunta18: "",
  pergunta19: "",
  pergunta20: "",
  pergunta21: "",
  pergunta22: "",
  pergunta23: "",
  pergunta24: "",
  pergunta25: "",
  pergunta26: "",
  pergunta27: "",
  pergunta28: "",
  pergunta29: "",
  pergunta30: "",
};

const validationSchema = Yup.object().shape({
  pergunta1: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta2: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta3: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta4: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta5: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta6: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta7: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta8: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta9: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta10: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta11: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta12: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta13: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta14: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta15: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta16: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta17: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta18: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta19: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta20: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta21: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta22: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta23: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta24: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta25: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta26: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta27: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta28: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta29: Yup.object().required(formErrors.errorMessages.answerRequired),
  pergunta30: Yup.object().required(formErrors.errorMessages.answerRequired),
});

export default {
  initialValues,
  validationSchema,
  validateOnBlur: false,
  validateOnChange: false,
};
